import {SOCKET_CHANNEL_OFF, SOCKET_CHANNEL_ON, SOCKET_SERVER_OFF, SOCKET_SERVER_ON} from "./constants";

const initialState = {
    channelStatus: 'off',
    serverStatus: 'unknown',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SOCKET_CHANNEL_ON:
            return {...state, channelStatus: 'on'};
        case SOCKET_CHANNEL_OFF:
            return {...state, channelStatus: 'off', serverStatus: 'unknown'};
        case SOCKET_SERVER_OFF:
            return {...state, serverStatus: 'off'};
        case SOCKET_SERVER_ON:
            return {...state, serverStatus: 'on'};
        default:
            return state;
    }
};

export default reducer;