import {
  take,
  fork,
  cancel,
  call,
  put,
  cancelled,
  select,
  all,
  takeEvery
} from 'redux-saga/effects'

import {
  AUTH_LOGIN_REQUESTING,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_ERROR,
} from '../constants'

import {
  unsetUser,
} from '../actions'

import {
  AUTH_USER_UNSET,
} from '../constants'

import {API_BASE_URL} from "../../../constants";

import {Fetch} from '../../../common/lib/api-fetch';
import {push} from 'connected-react-router';

function loginApi(email, password) {
  return Fetch(API_BASE_URL+"/auth/login", 'POST', {username: email, password})
}

function* loginFlow(email, password) {
  try {
    yield call(loginApi, email, password);
    yield put({type: AUTH_LOGIN_SUCCESS});
  } catch (error) {
    yield put({type: AUTH_LOGIN_ERROR, error})
  } finally {
    if (yield cancelled()) {
      yield put(push('/login'));
    }
  }
}

function* loginErrorWatcher() {
  const state = yield select();
  if (state.auth.module.user) {
    yield put(unsetUser());
    localStorage.removeItem('auth');
    yield put(push('/login'));
  }
}

function* loginRequestWatcher({email, password}) {
  const task = yield fork(loginFlow, email, password);
  const action = yield take([AUTH_USER_UNSET, AUTH_LOGIN_ERROR]);

  if (action.type === AUTH_USER_UNSET) yield cancel(task);
}

function* watchAll() {
  yield all([
    takeEvery(AUTH_LOGIN_REQUESTING, loginRequestWatcher),
    takeEvery(AUTH_LOGIN_ERROR, loginErrorWatcher),
  ]);
}

export default watchAll
