import {all, takeEvery, put} from 'redux-saga/effects'

import LoginSagas from './login/sagas';
import LogoutSagas from './logout/sagas';
import {AUTH_LOGIN_SUCCESS, AUTH_LOGOUT_SUCCESS, AUTH_SESSION_TOKEN_UPDATE_REQUESTING} from "./constants";
import {USER_GET_ME_REQUESTING, USER_GET_ME_SUCCESS, USER_GET_ME_ERROR, API_BASE_URL} from "../../constants";
import {setUser, unsetUser} from "./actions";
import {push} from 'connected-react-router';
import {Fetch} from "../../common/lib/api-fetch";


let sessionTimer = null;

function* askforSessionTokenUpdate() {
    yield put({type: AUTH_SESSION_TOKEN_UPDATE_REQUESTING});
}

function* userGetMeSuccessWatcher(user) {
    yield put(setUser(user));
    localStorage.setItem('auth', JSON.stringify(user));

    console.log("Starting up session token update");
    sessionTimer = setInterval(() => {
        console.log("Yielding session update");
        askforSessionTokenUpdate();
    }, 1000 * 5);
}

function* userGetMeErrorWatcher() {
    yield put({type: AUTH_LOGOUT_SUCCESS});
}

function* loginSuccessWatcher() {
    yield put({type: USER_GET_ME_REQUESTING});
}

function* logoutSuccessWatcher() {
    console.log("Logout success watcher");
    yield put(unsetUser());

    localStorage.removeItem('auth');
    yield put(push("/login"))

    if (sessionTimer) {
        clearInterval(sessionTimer);
        sessionTimer = null;
    }
}

function* sessionTokenUpdateWatcher() {
    console.log("Updating session token");
    yield Fetch(API_BASE_URL + "/auth/updateSession", 'GET', {});
}

function* watchAll() {
    yield all([
        takeEvery(AUTH_LOGIN_SUCCESS, loginSuccessWatcher),
        takeEvery(USER_GET_ME_SUCCESS, userGetMeSuccessWatcher),
        takeEvery(AUTH_LOGOUT_SUCCESS, logoutSuccessWatcher),
        takeEvery(USER_GET_ME_ERROR, userGetMeErrorWatcher),
        takeEvery(AUTH_SESSION_TOKEN_UPDATE_REQUESTING, sessionTokenUpdateWatcher)
    ]);
}

export default function* IndexSaga() {
    yield all([
        watchAll(),
        LoginSagas(),
        LogoutSagas(),
    ])
}
