class ExtendableError extends Error {
    constructor(message) {
        super();
        this.name = this.constructor.name;
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = (new Error(message)).stack;
        }
    }
}

// now I can extend

export class UnauthorizedError extends ExtendableError {}


export function handleApiErrors (response) {
    if (response.status===401) throw new UnauthorizedError(response.statusText);
    if (!response.ok) throw Error(response.statusText);
    return response
}
