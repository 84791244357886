import {
    ADMIN_COMPANIES_EVALUATION_GET_ME_FINISHED_REQUESTING,
    ADMIN_COMPANIES_EVALUATION_GET_ME_OPEN_REQUESTING,
    ADMIN_CREATE_USER_REQUESTING,
    ADMIN_FETCH_USERS_REQUESTING,
    ADMIN_RESET_COMPANIES_EVALUATION,
    ADMIN_SET_COMPANIES_EVALUATION,
    ADMIN_STATE_RESET_USER,
    ADMIN_UPDATE_USER_REQUESTING,
    USER_FETCH_REQUESTING
} from "./constants";

export const getUsersRequest = () => {
    return {
        type: ADMIN_FETCH_USERS_REQUESTING
    }
};

export const getUser = (id) => {
    return {
        type: USER_FETCH_REQUESTING,
        id
    }
};

export const resetUserInState = () => {
    return {
        type: ADMIN_STATE_RESET_USER
    }
};

export const doUpdateUser = (id, data) => {
    return {
        type: ADMIN_UPDATE_USER_REQUESTING,
        updateData: data,
        id
    }
};

export const doCreateUser = (data) => {
    return {
        type: ADMIN_CREATE_USER_REQUESTING,
        data
    }
};

export const getCompaniesEvaluations = (finished) => {
    return {
        type: finished ? ADMIN_COMPANIES_EVALUATION_GET_ME_FINISHED_REQUESTING : ADMIN_COMPANIES_EVALUATION_GET_ME_OPEN_REQUESTING
    }
};

export const resetAdminCompaniesEvaluationInState = () => {
    return {
        type: ADMIN_RESET_COMPANIES_EVALUATION
    }
};

export const setAdminCompaniesEvaluationInState = (companiesEvaluation) => {
    return {
        type: ADMIN_SET_COMPANIES_EVALUATION,
        data: companiesEvaluation
    }
}
