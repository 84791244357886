import {
    AUTH_LOGIN_REQUESTING,
} from '../constants'

export const loginRequest = ({ email, password }) => {
    return {
        type: AUTH_LOGIN_REQUESTING,
        email,
        password,
    }
}

