
export const informationToObject = (obj, label="information") => {
    if (obj && label in obj) {
        for(let key in obj[label].values) {
            obj[key] = obj[label].values[key];
        }
    }
    return obj;
};



export const informationToFlat = (obj, label="information") => {
    if (obj && label in obj) {
        obj[label].values = obj[label].values.reduce((acc,val) => {
            acc[val.key] = val.value;
            return acc;
        }, {});
        obj[label].template.keys = obj[label].template.keys.reduce((acc, val) => {
            acc[val.key] = val;
            return acc;
        }, {})

    }

    return obj;
};
