export const ADMIN_FETCH_USERS_REQUESTING = 'ADMIN_FETCH_USERS_REQUESTING';
export const ADMIN_FETCH_USERS_SUCCESS = 'ADMIN_FETCH_USERS_SUCCESS';
export const ADMIN_FETCH_USERS_ERROR = 'ADMIN_FETCH_USERS_ERROR';

export const USER_FETCH_REQUESTING = 'USER_FETCH_REQUESTING';
export const USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS';
export const USER_FETCH_ERROR = 'USER_FETCH_ERROR';

export const ADMIN_UPDATE_USER_REQUESTING = 'ADMIN_UPDATE_USER_REQUESTING';
export const ADMIN_UPDATE_USER_SUCCESS = 'ADMIN_UPDATE_USER_SUCCESS';
export const ADMIN_UPDATE_USER_ERROR = 'ADMIN_UPDATE_USER_ERROR';

export const ADMIN_CREATE_USER_REQUESTING = 'ADMIN_CREATE_USER_REQUESTING';
export const ADMIN_CREATE_USER_SUCCESS = 'ADMIN_CREATE_USER_SUCCESS';
export const ADMIN_CREATE_USER_ERROR = 'ADMIN_CREATE_USER_ERROR';

export const ADMIN_STATE_RESET_USER = 'ADMIN_STATE_RESET_USER';

export const ADMIN_COMPANIES_EVALUATION_GET_ME_FINISHED_REQUESTING = 'ADMIN_COMPANIES_EVALUATION_GET_ME_FINISHED_REQUESTING';
export const ADMIN_COMPANIES_EVALUATION_GET_ME_FINISHED_SUCCESS = 'ADMIN_COMPANIES_EVALUATION_GET_ME_FINISHED_SUCCESS';
export const ADMIN_COMPANIES_EVALUATION_GET_ME_FINISHED_ERROR = 'ADMIN_COMPANIES_EVALUATION_GET_ME_FINISHED_ERROR';

export const ADMIN_COMPANIES_EVALUATION_GET_ME_OPEN_REQUESTING = 'ADMIN_COMPANIES_EVALUATION_GET_ME_OPEN_REQUESTING';
export const ADMIN_COMPANIES_EVALUATION_GET_ME_OPEN_SUCCESS = 'ADMIN_COMPANIES_EVALUATION_GET_ME_OPEN_SUCCESS';
export const ADMIN_COMPANIES_EVALUATION_GET_ME_OPEN_ERROR = 'ADMIN_COMPANIES_EVALUATION_GET_ME_OPEN_ERROR';

export const ADMIN_RESET_COMPANIES_EVALUATION = 'ADMIN_RESET_COMPANIES_EVALUATION';
export const ADMIN_SET_COMPANIES_EVALUATION = 'ADMIN_SET_COMPANIES_EVALUATION';
