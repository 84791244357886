import {
    AUTH_LOGIN_REQUESTING,
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGIN_ERROR,
} from '../constants'

const initialState = {
    requesting: false,
    successful: false,
    messages: "",
    errors: "",
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_LOGIN_REQUESTING:
            return {
                requesting: true,
                successful: false,
                messages: 'Logger inn',
                errors: "",
            }

        case AUTH_LOGIN_SUCCESS:
            return {
                errors: "",
                messages: "Du er logget inn",
                requesting: false,
                successful: true,
            }

        case AUTH_LOGIN_ERROR:
            return {
                errors: action.error.toString(),
                messages: "",
                requesting: false,
                successful: false,
            }

        default:
            return state
    }
}

export default reducer
