import {HEADER_HIDE, HEADER_SHOW} from "../header/constants";

const initialState = {
  isVisible: true
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HEADER_HIDE:
      return {
        ...state,
        isVisible: false
      }
    case HEADER_SHOW:
      return {
        ...state,
        isVisible: true
      }
    default:
      return state;
  }
};

export default reducer;
