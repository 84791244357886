import {withStyles} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";

export const ButtonBlack = withStyles(theme => ({
  root: {
    color: 'white',
    backgroundColor: grey[900],
    '&:hover': {
      backgroundColor: grey[700],
    },
    'margin-right': '4px',
  },
  disabled: {
    backgroundColor: grey[300],
  }
}))(Button);

export const ButtonWhite = withStyles(theme => ({
  root: {
    color: 'black',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: grey[100],
    },
    'margin-right': '4px'
  },
}))(Button);
