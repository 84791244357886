import {AUTH_USER_SET, AUTH_USER_UNSET} from './constants'

import login from './login/reducer';
import logout from './logout/reducer';
import {combineReducers} from "redux";

const initialState = {
  user: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_USER_SET:
      return {
        user: action.user,
      }
    case AUTH_USER_UNSET:
      return {
        user: null,
      }
    default:
      return state
  }
}

export default combineReducers({login, logout, module: reducer})
