import {
    AUTH_LOGOUT_ERROR,
    AUTH_LOGOUT_REQUESTING, AUTH_LOGOUT_SUCCESS,
} from '../constants'

const initialState = {

};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_LOGOUT_REQUESTING:
            return {

            };
        case AUTH_LOGOUT_ERROR:
            return {

            };
        case AUTH_LOGOUT_SUCCESS:
            return {

            };
        default:
            return state
    }
}

export default reducer
