import { takeEvery, all, call, put, cancelled } from 'redux-saga/effects'

import {
    AUTH_LOGOUT_REQUESTING,
    AUTH_LOGOUT_ERROR,
    AUTH_LOGOUT_SUCCESS
} from '../constants'

import {Fetch} from "../../../common/lib/api-fetch";
import {API_BASE_URL} from "../../../constants";

function logoutApi () {
    return Fetch(API_BASE_URL+"/auth/logout", "POST", {});
}


function* logoutRequestWatcher() {
    try {
        yield call(logoutApi);
        yield put({type: AUTH_LOGOUT_SUCCESS});
    } catch (error) {
        yield put({type: AUTH_LOGOUT_ERROR, error})
    } finally {
        if (yield cancelled()) {

        }
    }
}

function* logoutErrorWatcher() {

}

function* watchAll() {
    yield all([
        takeEvery(AUTH_LOGOUT_REQUESTING, logoutRequestWatcher),
        takeEvery(AUTH_LOGOUT_ERROR, logoutErrorWatcher),
    ]);
}

export default watchAll
