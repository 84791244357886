import {
    ADMIN_COMPANIES_EVALUATION_GET_ME_FINISHED_ERROR,
    ADMIN_COMPANIES_EVALUATION_GET_ME_FINISHED_REQUESTING,
    ADMIN_COMPANIES_EVALUATION_GET_ME_FINISHED_SUCCESS, ADMIN_COMPANIES_EVALUATION_GET_ME_OPEN_ERROR,
    ADMIN_COMPANIES_EVALUATION_GET_ME_OPEN_REQUESTING,
    ADMIN_COMPANIES_EVALUATION_GET_ME_OPEN_SUCCESS,
    ADMIN_CREATE_USER_ERROR,
    ADMIN_CREATE_USER_REQUESTING,
    ADMIN_CREATE_USER_SUCCESS,
    ADMIN_RESET_COMPANIES_EVALUATION, ADMIN_SET_COMPANIES_EVALUATION,
    ADMIN_STATE_RESET_USER,
    ADMIN_UPDATE_USER_ERROR,
    ADMIN_UPDATE_USER_REQUESTING,
    ADMIN_UPDATE_USER_SUCCESS,
    USER_FETCH_ERROR,
    USER_FETCH_REQUESTING,
    USER_FETCH_SUCCESS
} from "./constants";

const initialState = {
    users: {
        search: "",
        data: null
    },
    user: {
        user: null,
        isLoading: false,
        isUpdating: false,
        isUpdated: false,
        isUpdateError: false
    },
    companiesEvaluations: {
        finished: null,
        open: null
    },
    companiesEvaluation: {
        companiesEvaluation: null,
        isLoading: false,
        isUpdating: false,
        isUpdated: false,
        isUpdateError: false
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_FETCH_REQUESTING:
            return {
                ...state,
                user: {
                    ...state.user,
                    isLoading: true
                }
            };
        case USER_FETCH_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    user: action.data,
                    isLoading: false
                }
            };
        case USER_FETCH_ERROR:
            return {
                ...state,
                user: {
                    ...state.user,
                    isLoading: false
                }
            }
        case ADMIN_UPDATE_USER_REQUESTING:
            return {
                ...state,
                user: {
                    ...state.user,
                    isUpdating: true,
                    isUpdated: false,
                    isUpdateError: false
                }
            };
        case ADMIN_UPDATE_USER_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    user: action.data,
                    isUpdated: true,
                    isUpdating: false
                }
            };
        case ADMIN_UPDATE_USER_ERROR:
            return {
                ...state,
                user: {
                    isUpdated: false,
                    isUpdating: false,
                    isUpdateError: true
                }
            };
        case ADMIN_CREATE_USER_REQUESTING:
            return {
                ...state,
                user: {
                    ...state.user,
                    isUpdating: true,
                    isUpdated: false,
                    isUpdateError: false
                }
            };
        case ADMIN_CREATE_USER_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    user: action.data,
                    isUpdated: true,
                    isUpdating: false
                }
            };
        case ADMIN_CREATE_USER_ERROR:
            return {
                ...state,
                user: {
                    ...state.user,
                    isUpdated: false,
                    isUpdating: false,
                    isUpdateError: true
                }
            };
        case ADMIN_STATE_RESET_USER:
            return {
                ...state,
                user: {
                    user: null,
                    isUpdated: false,
                    isUpdating: false,
                    isUpdateError: false
                }
            };

        case ADMIN_COMPANIES_EVALUATION_GET_ME_FINISHED_REQUESTING:
            return {
                ...state
            };
        case ADMIN_COMPANIES_EVALUATION_GET_ME_FINISHED_SUCCESS:
            return {
                ...state,
                companiesEvaluations: {
                    ...state.companiesEvaluations,
                    finished: action.data
                }
            };
        case ADMIN_COMPANIES_EVALUATION_GET_ME_FINISHED_ERROR:
            return {
                ...state
            };
        case ADMIN_COMPANIES_EVALUATION_GET_ME_OPEN_REQUESTING:
            return {
                ...state
            };
        case ADMIN_COMPANIES_EVALUATION_GET_ME_OPEN_SUCCESS:
            return {
                ...state,
                companiesEvaluations: {
                    ...state.companiesEvaluations,
                    open: action.data
                }
            };
        case ADMIN_COMPANIES_EVALUATION_GET_ME_OPEN_ERROR:
            return {
                ...state
            };
        case ADMIN_RESET_COMPANIES_EVALUATION:
            return {
                ...state,
                companiesEvaluation: {
                    companiesEvaluation: null,
                    isLoading: false,
                    isUpdating: false,
                    isUpdated: false,
                    isUpdateError: false
                }
            };
        case ADMIN_SET_COMPANIES_EVALUATION:
            return {
                ...state,
                companiesEvaluation: {
                    companiesEvaluation: action.data,
                    isLoading: false,
                    isUpdating: false,
                    isUpdated: false,
                    isUpdateError: false
                }
            };
        default:
            return state;
    }
};

export default reducer;
