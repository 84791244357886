import {
    AUTH_USER_SET,
    AUTH_USER_UNSET
} from './constants';

import {loginRequest} from "./login/actions";
import {logoutRequest} from './logout/actions';

const setUser = ({user}) => {
    return {
        type: AUTH_USER_SET,
        user,
    }
}

const unsetUser = () => {
    return {
        type: AUTH_USER_UNSET,
    }
}

export {
    setUser,
    unsetUser,
    loginRequest,
    logoutRequest
};
