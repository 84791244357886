export const AUTH_LOGIN_REQUESTING = 'AUTH_LOGIN_REQUESTING';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';
export const AUTH_LOGOUT_REQUESTING = 'AUTH_LOGOUT_REQUESTING';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_ERROR = 'AUTH_LOGOUT_ERROR';

export const AUTH_USER_SET = 'AUTH_USER_SET';
export const AUTH_USER_UNSET = 'AUTH_USER_UNSET';

export const AUTH_SESSION_TOKEN_UPDATE_REQUESTING = 'AUTH_SESSION_TOKEN_UPDATE_REQUESTING';
