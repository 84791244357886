import timer from 'battery-friendly-timer';
import React, {useState} from "react";
import './index.scss';

const Reload = () => {
  const [previousHtml, setPreviousHtml] = useState();
  const [show, setShow] = useState(false);

  timer.setInterval(
    () => fetch(window.protocol+"//"+window.host+'/index.html')
      .then(response => {
        if (response.status !== 200) {
          throw new Error('offline');
        }
        return response.text();
      })
      .then(html => {
        if (!previousHtml) {
          setPreviousHtml(html);
          return;
        }
        if (previousHtml !== html) {
          setPreviousHtml(html);
          setShow(true);
        }
      })
      .catch(err => { /* do nothing */ }),
    60 * 1000, // tryDelay: 1 minutes
    24 * 60 * 60 * 1000 // forceDelay: 1 day
  );

  return (
    <div className={"reload "+(show ? "show" : "")} onClick={() => window.location.reload()}>
      Det finnes en ny versjon av programmet. Klikk her for å laste på nytt
    </div>
  )
}

export default Reload;
