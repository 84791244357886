import {
    AUTH_LOGOUT_REQUESTING,
} from '../constants'

export const logoutRequest = () => {
    return {
        type: AUTH_LOGOUT_REQUESTING,
    }
}

