import {all, takeEvery, cancelled, put, call, select} from 'redux-saga/effects'

import {USER_GET_ME_ERROR, USER_GET_ME_REQUESTING, USER_GET_ME_SUCCESS} from "./constants";
import {Fetch} from "../../common/lib/api-fetch";
import {API_BASE_URL} from "../../constants";
import {push} from 'connected-react-router';

function apiUsersMe() {
    return Fetch(API_BASE_URL + "/users/me", 'GET', {});
}

function* userGetMeWatcher() {
    let user;
    try {
        const result = yield call(apiUsersMe);
        user = result.data;
        yield put({type: USER_GET_ME_SUCCESS, user})
        const state = yield select();
        if (state.router.location.pathname === "/login") {
            yield put(push("/dashboard"));
        }
    } catch (error) {
        console.log("Error", error);
        yield put({type: USER_GET_ME_ERROR, error})
    } finally {
        if (yield cancelled()) {
            yield put(push("/"));
        }
    }

    return user;
}

function* watchAll() {
    yield all([
        takeEvery(USER_GET_ME_REQUESTING, userGetMeWatcher),
    ]);
}

export default function* IndexSaga() {
    yield all([
        watchAll(),
    ])
}
